import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const social = this.props.data.site.siteMetadata.social
        const siteTitle = this.props.data.site.siteMetadata.title
        const { previous, next } = this.props.pageContext

        return (
            <Layout location={this.props.location} title={siteTitle} social={social}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                    slug={post.fields.slug}
                />
                <h1>{post.frontmatter.title}</h1>
                <div className="post-content">
                    <p className="post-note">
                        {post.frontmatter.date} &nbsp; • {post.timeToRead} min read
                    </p>
                    <p className="post-note">
                        I wrote this post for my newsletter, <Link to="/newsletter">sign up here</Link> to get emails like these every week.
                    </p>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
                <hr
                    style={{
                        marginBottom: rhythm(1),
                        backgroundColor: 'rgba(0, 79, 132,0.8)'
                    }}
                />
                <Subscribe titleText={'Are you interested to get article like this every Saturday in your inbox?'} />
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
              </Link>
                        )}
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        social {
          twitter
          facebook
          github
          instagram
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      timeToRead
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      fields {
        slug
      }
    }
  }
`
